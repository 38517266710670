/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 275px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAAB5m8tOmUoZ//EABkQAQACAwAAAAAAAAAAAAAAAAEAAgMQEf/aAAgBAQABBQKrxutd5QCf/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/ARF//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAECAQE/Aauf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIQIQAxkf/aAAgBAQAGPwKgDlqvJG4//8QAGhABAAIDAQAAAAAAAAAAAAAAAQAhEBExgf/aAAgBAQABPyF7gas3NOWMOMcB9OP/2gAMAwEAAgADAAAAEMv/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxBGp//EABcRAAMBAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QGMxf/8QAGhABAAIDAQAAAAAAAAAAAAAAARFBABAxYf/aAAgBAQABPxDnQQHasFEpmEzoUEURkTFHVUYmjw1//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"wisconsin flag\"\n        title=\"wisconsin flag\"\n        src=\"/static/6affbeb97f7becdd106ddeb606ede48c/85f30/wisconsin-flag.jpg\"\n        srcset=\"/static/6affbeb97f7becdd106ddeb606ede48c/4d5fb/wisconsin-flag.jpg 166w,\n/static/6affbeb97f7becdd106ddeb606ede48c/85f30/wisconsin-flag.jpg 275w\"\n        sizes=\"(max-width: 275px) 100vw, 275px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Wisconsin State Flag"), "\n", React.createElement(_components.p, null, "While being led by two ", React.createElement(_components.strong, null, "Miami Indians"), " as guides, explorer Jacques ", React.createElement(_components.strong, null, "Marquette"), " and Louis ", React.createElement(_components.strong, null, "Joliet"), " traveled down a river (in what is now Wisconsin) that led them to the Mississippi. This river, as it was written, was called the ", React.createElement(_components.strong, null, "“Meskousing”"), ". In the native language of the Miami Indians it meant “stream that meanders through something red” –perhaps referring to red sandstone that borders some parts of the river. Later Louis Joliet drew a map of their travels that included the same river but in this case he spelled it “Miskonsing”. The map and the accounts of the travels were later published in a book and the name again took on a slightly different spelling: “Mescousin”. French explorer Rene Robert Cavelier, Sieur de la Salle then misinterpreted Marquette’s original cursive spelling, turning the “M” into “Ou”. The river could then be seen in its written form as ", React.createElement(_components.strong, null, "“Ouisconsing”"), " and from 1682 to the 1820’s the name stuck. Then in the 1820’s the U.S. Government Americanized the French spelling of the misinterpreted word by replacing the “Ou” with a “W” (which is the English pronunciation of “ou”). The territory became official in 1836 and the name would forever remain “Wisconsin”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
